import React, { Suspense } from "react";
import { Provider as AuthProvider } from "context/Auth";
import { Provider as IntlProvider } from "context/Intl";
import { Provider as FlashMessagesProvider } from "context/FlashMessages";
import { BrowserRouter } from "react-router-dom";

import "assets/scss/main.scss";

import FlashMessages from "components/App/FlashMessages";
import ErrorBoundary from "components/App/ErrorBoundary";
import Loader from "components/App/Loader";
import ModuleRouter from "./ModuleRouter";

const App: React.FC = () => {
    return (
        <AuthProvider>
            <IntlProvider>
                <ErrorBoundary>
                    <FlashMessagesProvider FlashComponent={FlashMessages}>
                        <BrowserRouter basename={process.env.PUBLIC_URL}>
                            <Suspense fallback={<Loader />}>
                                <ModuleRouter />
                            </Suspense>
                        </BrowserRouter>
                    </FlashMessagesProvider>
                </ErrorBoundary>
            </IntlProvider>
        </AuthProvider>
    );
};

export default App;
