import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { Context as AuthContext } from "context/Auth";
import { Context as IntlContext } from "context/Intl";

import Logo from "assets/images/icon.png";

const Sidebar: React.FC<{
    openned: boolean;
    openMenu: (openned: boolean) => void;
}> = ({ openned, openMenu }) => {
    const auth = useContext(AuthContext);
    const intl = useContext(IntlContext);

    return (
        <React.Fragment>
            {openned && <div className={"sidebar-overlay"} onClick={() => openMenu(false)}></div>}
            <div className={openned ? `sidebar open` : `sidebar`}>
                <div className="sidebar-header">
                    <img className="sidebar-header-icon" src={Logo} alt="Application icon"></img>
                    <h2 className="sidebar-header-title">Guinot - Mary Cohr</h2>
                </div>
                <div className="sidebar-account">
                    <div className="sidebar-account-wrap">
                        <img className="sidebar-account-logo" src={Logo} alt="Logo de l'entreprise"></img>
                        <span className="sidebar-account-name">
                            {auth?.account?.user?.first_name ?? "John"} {auth?.account?.user?.last_name ?? "Smith"}
                        </span>
                    </div>
                </div>

                <div className="sidebar-nav">
                    <h2>{intl.formatMessage("menu.left.general_menu")}</h2>

                    <ul>
                        {auth.isAllowed("review_visit.add") && (
                            <li>
                                <Link to="/review_visit/add">{intl.formatMessage("menu.left.review_visit_add")}</Link>
                            </li>
                        )}
                        {auth.isAllowed("review_visit.update") && (
                            <li>
                                <Link to="/review_visit/calendar">
                                    {intl.formatMessage("menu.left.review_visits_calendar")}
                                </Link>
                            </li>
                        )}
                        <li>
                            <Link to="/review_visit/list">{intl.formatMessage("menu.left.review_visits_list")}</Link>
                        </li>
                        <li>
                            <Link to="/exit">{intl.formatMessage("menu.left.return_to_adm")}</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Sidebar;
