import React, { Suspense, useState } from "react";

import Loader from "components/App/Loader";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";

const Main: React.FC = (props) => {
    const [isMenuOpen, openMenu] = useState<boolean>(false);

    return (
        <div className="layout">
            <Sidebar openned={isMenuOpen} openMenu={openMenu} />

            <div className="layout-main">
                <Topbar openMenu={openMenu} />
                <Suspense fallback={<Loader />}>
                    <div className="container-main">{props.children}</div>
                </Suspense>
            </div>
        </div>
    );
};

export default Main;
