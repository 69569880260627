import React from "react";

// import { Context as AuthContext } from "context/Auth";

const Topbar: React.FC<{ openMenu: (openned: boolean) => void }> = ({ openMenu }) => {
    // const auth = useContext(AuthContext);

    return (
        <div className="topbar">
            <label className="topbar-toggler" onClick={() => openMenu(true)}>
                <i className="material-icons">menu</i>
            </label>
        </div>
    );
};

export default Topbar;
