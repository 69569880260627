import React from "react";
import fetch from "dataProvider/fetch";
import AuthenticationService from "dataProvider/AuthenticationService";

interface Account {
    user: {
        id: string;
        gender: string;
        first_name: string;
        last_name: string;
        username: string;
        email: string;
        phone: string;
        brand: string;
        role: {
            id: string;
            name: string;
        };
        delegate_code?: string;
    };
    capabilities: string[];
    resources: any;
}

export interface AuthenticationProviderInterface extends Readonly<{}> {
    isLoggedIn: Boolean;
    context: boolean;
    account: Account | null;
    initialized: Boolean;
    login: (token: string) => Promise<void>;
    logout: () => Promise<void>;
    isAllowed: (acl: string) => boolean;
    isAuthor: (authorId: string) => boolean;
    setContext: (context: boolean) => void;
    hasContext: () => boolean;
    refresh: () => Promise<void>;
}

const ACCOUNT_KEY: string = "account";
const CONTEXT_KEY: string = "context";

function getAccount(): Account | null {
    if (fetch.sessionIsExpired()) {
        return null;
    }
    try {
        const account = localStorage.getItem(ACCOUNT_KEY);
        if (account) {
            return JSON.parse(account);
        }
        return null;
    } catch (err) {
        return null;
    }
}

function isLoggedIn(): boolean {
    return !!getAccount();
}

const AuthContext = React.createContext<AuthenticationProviderInterface>({} as AuthenticationProviderInterface);

class AuthProvider extends React.Component<Readonly<{}>, AuthenticationProviderInterface> {
    constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            isLoggedIn: false,
            context: false,
            account: null,
            initialized: false,
            login: this.login,
            logout: this.logout,
            isAllowed: this.isAllowed,
            isAuthor: this.isAuthor,
            setContext: this.setContext,
            hasContext: this.hasContext,
            refresh: this.refresh,
        };
    }

    componentDidMount = async () => {
        this.refresh();
    };

    refresh = async () => {
        if (isLoggedIn()) {
            try {
                const res = await AuthenticationService.fresh();
                const account = Object.assign({}, res.data.data) as Account;
                localStorage.setItem(ACCOUNT_KEY, JSON.stringify(account));
                this.setState({ isLoggedIn: true, account: account, initialized: true });
            } catch (e) {
                this.logout();
            }
        } else {
            this.setState({ isLoggedIn: false, account: null, initialized: true });
        }
    };

    login = async (token: string) => {
        const res = await AuthenticationService.login(token);
        const account = Object.assign({}, res.data.data) as Account;
        localStorage.setItem(ACCOUNT_KEY, JSON.stringify(account));
        this.setState({ isLoggedIn: true, account: account, initialized: true });
    };

    logout = async () => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const bugIE = await AuthenticationService.logout();
        } finally {
            localStorage.removeItem(ACCOUNT_KEY);
            localStorage.removeItem(CONTEXT_KEY);
            this.setState({ isLoggedIn: false, account: null });
        }
    };

    isAllowed = (acl: string) => {
        if (!this.state.account) {
            return false;
        }

        const capabilities = this.state.account.capabilities;
        for (const cap of capabilities) {
            // Matches exact cap
            if (acl === cap) {
                return true;
            }

            // Matches super cap
            const index = acl.indexOf(cap);
            if (index === 0 && acl.substr(cap.length, 1) === ".") {
                return true;
            }

            // Matches joker caps
            if (acl.indexOf("*") !== -1) {
                const subAcl = acl.substr(0, acl.indexOf("*") - 1);
                const index = cap.indexOf(subAcl);
                if (index === 0 && cap.substr(subAcl.length, 1) === ".") {
                    return true;
                }
            }
        }

        return false;
    };

    isAuthor = (authorID: string) => {
        if (!this.state.account) {
            return false;
        }

        return this.state.account.user.id === authorID;
    };

    setContext = (context: boolean) => {
        localStorage.setItem(CONTEXT_KEY, JSON.stringify(context));
    };

    hasContext = () => {
        let context = localStorage.getItem(CONTEXT_KEY);
        return context ? JSON.parse(context) : false;
    };

    render() {
        return <AuthContext.Provider value={this.state}>{this.props.children}</AuthContext.Provider>;
    }
}

const Consumer = AuthContext.Consumer;

export { AuthProvider as Provider, Consumer, AuthContext as Context };
