import React, { useContext, useEffect } from "react";
import fetch from "dataProvider/fetch";
import { Context as AuthContext } from "context/Auth";
import { Context as IntlContext } from "context/Intl";
import { Context as FlashContext } from "context/FlashMessages";

const LogoutOnExpirationComponent: React.FC = ({ children }) => {
    const auth = useContext(AuthContext);
    const intl = useContext(IntlContext);
    const flash = useContext(FlashContext);

    const logoutIfSessionIsExpired = () => {
        if (auth.isLoggedIn && fetch.sessionIsExpired()) {
            auth.logout();
            flash.addWarning(intl.formatMessage("login.logout.inactivity"));
        }
    };
    useEffect(() => {
        let logoutIntervalId = setInterval(logoutIfSessionIsExpired, 10000);
        logoutIfSessionIsExpired();
        return () => {
            clearInterval(logoutIntervalId);
        };
    });
    return <React.Fragment>{children}</React.Fragment>;
};

export default LogoutOnExpirationComponent;
