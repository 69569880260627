import React from "react";

type Props = {
    message?: string;
    size?: "small" | "xsmall";
};

class Loader extends React.Component<Props> {
    render() {
        let className = "loader";
        if (this.props.size) {
            className += ` loader--${this.props.size}`;
        }

        return (
            <div className="loader-wrapper">
                <div>
                    <div className={className} />
                    <div className="text-center">
                        {this.props.message ? <p className="loader-text">{this.props.message}</p> : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default Loader;
