import React, { useContext } from "react";

import { Context as AuthContext } from "context/Auth";
import { Route, RouteProps, Redirect } from "react-router-dom";

interface Props extends RouteProps {
    capability: string;
}

const ProtectedRoute: React.FC<Props> = ({ capability, strict = true, ...props }) => {
    const auth = useContext(AuthContext);

    if (auth.isAllowed(capability)) {
        return <Route strict={strict} {...props} />;
    }
    return <Redirect to="/" />;
};

export default ProtectedRoute;
