import fetch from "./fetch";

class Authentication {
    login(token: string) {
        return fetch.post(`authentication/login/`, { token });
    }

    logout() {
        return fetch.post(`authentication/logout/`);
    }

    fresh() {
        return fetch.get(`authentication/fresh/`);
    }
}

export default new Authentication();
