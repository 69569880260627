import React from "react";
import { IntlProvider, injectIntl, IntlShape as BaseShape } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import LanguageService from "dataProvider/Language";
import LoaderUI from "ui/Loader";

export type IntlShape = Omit<Omit<BaseShape, "formatMessage">, "formatDate"> & {
    formatMessage: (id: string, values?: Record<string, PrimitiveType>) => string;
    formatDate: (value: string | number | Date | undefined) => string;
    formatDateTime: (value: string | number | Date | undefined) => string;
    formatCurrency: (value: number, currency?: string, round?: boolean, minimumFractionDigits?: number) => string;
    parseNumber: (value: string, maximumFractionDigits?: number) => string;
    myFormatNumber: (value: any, maximumFractionDigits?: number) => string;
    formatNumberAbr: (value: any) => string;
};

const IntlContext = React.createContext<IntlShape>({} as IntlShape);

class BaseProvider extends React.Component<{ intl: BaseShape }> {
    formatMessage = (id: string, values?: Record<string, PrimitiveType>): string => {
        return this.props.intl.formatMessage({ id }, values);
    };
    formatDate = (value: string | number | Date | undefined) => {
        if (typeof value === "string") {
            value = value.replace("/", "-").substr(0, 10);
        }
        return this.props.intl.formatDate(value);
    };
    formatDateTime = (value: string | number | Date | undefined) => {
        let date = value;
        if (typeof value === "string") {
            date = value.replace("/", "-").substr(0, 10);
        }
        return this.props.intl.formatDate(date) + " " + this.props.intl.formatTime(value);
    };

    formatCurrency = (value: number, currency: string = "EUR", round = false, minimumFractionDigits = 0) => {
        return this.props.intl.formatNumber(value, {
            style: "currency",
            currency: currency,
            minimumFractionDigits: minimumFractionDigits,
            maximumFractionDigits: round ? 0 : 2,
        });
    };

    parseNumber = (value: string, maximumFractionDigits = 2) => {
        if (!value) return "";

        // Uniquement chiffre ou ,
        value = value.replace(".", ",");
        value = maximumFractionDigits > 0 ? value.replace(/[^\d,]/g, "") : value.replace(/[^\d]/g, "");

        // une seule virgule max
        if (maximumFractionDigits > 0 && (value.match(/,/g) || []).length > 1) {
            value = value.replace(/,\d*$/g, "");
        }

        if (!value) return "";

        value = this.myFormatNumber(value, maximumFractionDigits);

        value = value.replace(/,/g, ".");
        value = value.replace(/\s/g, "");

        return value;
    };

    myFormatNumber = (number: any, maximumFractionDigits = 2) => {
        if (!number) return "";

        if (number < 0) {
            number = 0.0;
        }

        let value: string = number.toString().replace(".", ",");

        let fractionPart = "";
        let integerPart = value;
        let commatIndex = value.indexOf(",");
        if (maximumFractionDigits > 0 && commatIndex !== -1) {
            fractionPart = value.substr(commatIndex, maximumFractionDigits + 1);
            integerPart = value.substr(0, commatIndex);
        }

        return (
            this.props.intl.formatNumber(parseFloat(integerPart), {
                maximumFractionDigits: maximumFractionDigits,
            }) + fractionPart
        );
    };

    formatNumberAbr = (value: any) => {
        if (value / 1000000 > 1) {
            return (value / 1000000).toFixed(2).replace(".", ",") + "m";
        }
        if (value / 1000 > 1) {
            return (value / 1000).toFixed(2).replace(".", ",") + "k";
        }

        return (value * 1).toFixed(2);
    };

    render() {
        return (
            <IntlContext.Provider
                value={{
                    ...this.props.intl,
                    formatMessage: this.formatMessage,
                    formatDate: this.formatDate,
                    formatDateTime: this.formatDateTime,
                    formatCurrency: this.formatCurrency,
                    parseNumber: this.parseNumber,
                    myFormatNumber: this.myFormatNumber,
                    formatNumberAbr: this.formatNumberAbr,
                }}
            >
                {this.props.children}
            </IntlContext.Provider>
        );
    }
}

const SubProvider = injectIntl(BaseProvider);

class Provider extends React.Component {
    state = {
        translations: {},
        localeCode: "fr",
        status: "IDLE",
    };

    componentDidMount = () => {
        this.loadTranslations(this.state.localeCode);
    };

    loadTranslations = (localeCode: string) => {
        this.setState({
            status: "LOADING",
        });
        return LanguageService.list(localeCode).then((res) => {
            this.setState({
                translations: res.data.data,
                status: "IDLE",
                localeCode,
            });
        });
    };

    render() {
        if (this.state.status === "LOADING") {
            const loadingMessages: HashMap<string> = {
                fr: "Chargement...",
            };
            return (
                <div className="loader-backdrop-wrapper loader-backdrop-wrapper--full">
                    <div className="loader-backdrop" />
                    <LoaderUI message={loadingMessages[this.state.localeCode]} />
                </div>
            );
        }
        return (
            <IntlProvider locale={this.state.localeCode} key={this.state.localeCode} messages={this.state.translations}>
                <SubProvider>{this.props.children}</SubProvider>
            </IntlProvider>
        );
    }
}

const Consumer = IntlContext.Consumer;

export { Consumer, Provider, IntlContext as Context };
