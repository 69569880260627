import React, { useContext, useEffect } from "react";
import { Context as AuthContext } from "context/Auth";
import { Main as MainLayout } from "components/Layout/";
import LogoutOnExpiration from "components/App/LogoutOnExpiration";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useCallback } from "react";
import Loader from "ui/Loader";
import ProtectedRoute from "components/Secured/ProtectedRoute";

const AuthModule: React.FC = () => {
    const auth = useContext(AuthContext);

    const ReviewVisit = React.lazy(() => import("app/ReviewVisit"));
    const List = React.lazy(() => import("app/ReviewVisit/List"));

    const GuinotTheme = React.lazy(() => import("components/Themes/guinotTheme"));
    const MaryCohrTheme = React.lazy(() => import("components/Themes/maryCohrTheme"));
    const DefaultTheme = React.lazy(() => import("components/Themes/defaultTheme"));

    let isAdmin = auth.isAllowed("review_visit.create");

    const ThemeSelector: React.FC = ({ children }) => {
        const chosenTheme = auth.account?.user.brand;

        return (
            <>
                <React.Suspense fallback={<></>}>
                    {chosenTheme === "M" && <MaryCohrTheme />}
                    {chosenTheme === "G" && <GuinotTheme />}
                    {(chosenTheme === "" || chosenTheme === "G,M") && <DefaultTheme />}
                </React.Suspense>
                {children}
            </>
        );
    };

    return (
        <LogoutOnExpiration>
            <ThemeSelector>
                <MainLayout>
                    <Switch>
                        <Route path={`/review_visit`} render={() => <ReviewVisit />} />
                        <Route
                            path={`/exit`}
                            component={() => {
                                // @ts-ignore
                                window.location.href = process.env.REACT_APP_CARBONE_URL;
                                return null;
                            }}
                        />
                        <Route path={`/review_visit/list`} render={() => <List />} />
                        <ProtectedRoute
                            capability="review_visit.*"
                            path="/"
                            render={() =>
                                isAdmin ? <Redirect to="/review_visit" /> : <Redirect to="/review_visit/list" />
                            }
                        />
                        <Route path="/" render={() => ""} />
                    </Switch>
                </MainLayout>
            </ThemeSelector>
        </LogoutOnExpiration>
    );
};

const NoAuthModule: React.FC = () => {
    const auth = useContext(AuthContext);
    const history = useHistory();
    const location = useLocation();

    const submitLogin = useCallback(async () => {
        const queryString = require("query-string");
        const queryParams = queryString.parse(location.search);

        if (!queryParams.token) {
            window.location.href = `${process.env.REACT_APP_CARBONE_URL}`;
        } else {
            try {
                await auth.login(queryParams.token);
                history.push("/");
            } catch (err) {
                window.location.href = `${process.env.REACT_APP_CARBONE_URL}`;
            }
        }
    }, [auth, history, location.search]);

    useEffect(() => {
        submitLogin();
    }, [submitLogin]);

    return null;
};

const ModuleRouter: React.FC = () => {
    const auth = useContext(AuthContext);
    const location = useLocation();
    const queryString = require("query-string");
    const queryParams = queryString.parse(location.search);

    if (queryParams.token) {
        return <NoAuthModule />;
    }

    if (auth.isLoggedIn) {
        return <AuthModule />;
    }

    if (!auth.initialized) {
        return <Loader />;
    }

    window.location.href = `${process.env.REACT_APP_CARBONE_URL}`;
    return null;
};

export default ModuleRouter;
