import React from "react";

interface Props {
    duration: number | null;
    animationDuration: number;
    type: string;
    content: string;
    onFadeOut: () => void;
}

class FlashMessage extends React.Component<Props> {
    state = {
        show: true,
    };

    timeOutId: NodeJS.Timeout | null = null;

    fadeOut() {
        const { animationDuration, onFadeOut } = this.props;
        this.setState({ show: false });
        this.timeOutId && clearTimeout(this.timeOutId);
        this.timeOutId = setTimeout(() => onFadeOut(), animationDuration);
    }

    componentDidMount() {
        if (this.props.duration) {
            this.timeOutId = setTimeout(() => this.fadeOut(), this.props.duration);
        }
    }

    componentWillUnmount() {
        this.timeOutId && clearTimeout(this.timeOutId);
    }

    render() {
        const { content, type } = this.props;
        const showClassName = this.state.show ? `flash-message--show` : `flash-message--hide`;

        return (
            <div className={`flash-message flash-message--${type} ${showClassName}`}>
                <div className="flash-message__content">{content}</div>
                <button className="flash-message__remove-btn material-icons" onClick={() => this.fadeOut()}>
                    clear
                </button>
            </div>
        );
    }
}

export default FlashMessage;
