import React from "react";
import { ErrorInfo } from "react";
import { withIntl, IntlProps } from "context/Intl/";

interface StateInterface {
    hasError: boolean;
    error?: Error;
    errorInfo?: ErrorInfo;
}

class ErrorBoundary extends React.Component<IntlProps, StateInterface> {
    readonly state = {
        hasError: false,
    };

    componentDidCatch(error: any, errorInfo: any) {
        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo,
        });
    }

    render() {
        if (this.state.hasError) {
            const { intl } = this.props;
            return (
                <React.Fragment>
                    <h2>{intl.formatMessage("generic.error")}</h2>
                </React.Fragment>
            );
        }
        return this.props.children;
    }
}

export default withIntl(ErrorBoundary);
